

.form-container {
    width: 900px; /* Adjust the width as needed */
    height: 90%; /* Adjust the height or use max-height as needed */
    border: 1px solid #ccc;
    padding: 20px;
    margin-left: 100px;
    overflow-y: auto; /* Enables vertical scrolling */
    box-shadow: 0 0 10px rgba(0,0,0,0.1); /* Optional: adds shadow for better visibility */
  }
  
  input, textarea {
    width: 100%;
    margin: 10px 0;
    padding: 8px;
  }
  h2 {
    text-align: center;
  }