body {
    margin: 0;
    padding: 0;
    background-color: #fff;
    color: #000;
    font-family: 'Montserrat', sans-serif;
    /* height: 100%; */
  }
  h3{
    font-size: 1.3rem !important;
  }
  h1, .h1 {
    font-size: 2rem !important;
}


  .icon {
    vertical-align: middle;
    line-height: 1px;
    font-size: 20px;
  }
  .icon_header {
    vertical-align: middle;
    line-height: 1px;
    font-size: 26px;
  }
  .icon, .icon_header {
    margin-right: 5px;
  }
  
  .close_icon {
    color: red;
    margin-left: 30px;
    margin-top: 10px;
    cursor: pointer;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: 260px 1fr 1fr 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      'sidebar header header header'
      'sidebar main main main';
    height: 100vh;
  }
  
  /* Header  */
  .header {
    grid-area: header;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    /* box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35); */
  }
  
  .menu-icon {
    display: none;
  }
  /* End Header  */
  
  /* Sidebar  */
  #sidebar {
    grid-area: sidebar;
    height: 100%;
    background-color: #000;
    overflow-y: auto;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  .active {
    color: #B1081A !important;
    font-size: 18px; 
  }
  .active .sidebar-list-item{
    background-color: #fff !important;
  }
  
  
  .sidebar-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px 0px 30px;
    margin-bottom: 50px;
  }
  
  .sidebar-title > span {
    display: none;
  }
  
  .sidebar-brand {
    /* margin-top: 15px; */
    font-size: 20px;
    font-weight: 700;
  }
  
  .sidebar-list {
    padding: 0;
    list-style-type: none;
  }
  
  .sidebar-list-item {
    padding: 17px;
    font-size: 0.979rem !important; 
  border-radius: 15px 0px 0px 15px; }
  
  .sidebar-list-item:hover {
    background-color: #ffffff;
    cursor: pointer;
    color: #B1081A !important;

    font-size: 0.979rem !important;
  }
  
  .sidebar-list-item > a ,a {
    text-decoration: none;
    color: #fff !important;
    font-size: 0.979rem !important;
  }
  
  .sidebar-responsive {
    display: inline !important;
    position: absolute;
    /*
      we want the z-index of the sidebar higher so that
      the charts are not showing over the sidebar 
      on small screens
    */
    z-index: 12 !important;
  }
  
  /* End Sidebar  */
  
  .back_image {
    position: relative;
  }
  table{
    border-radius: 10px !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px !important;
    overflow: hidden;
  }
  .ant-table-cell {
    padding: 0.5rem 0.5rem !important;
  }
  
  .back_image::before {
    content: "";
    position: absolute;
    top: -45px;
    left: -38px;
    width: 90%;
    height: 150%;
    /* background-image: url('../../image/tyremask.png'); */
    background-size: cover;
    opacity: 0.1;
    z-index: -1;
    background-repeat: no-repeat;
    rotate: 350deg;

  }
  .siderbar-logo{
    width: 100%;
    background-color: transparent;
    z-index: 3;
    position: relative;  }
  .imgediv{
    width: 80%;
    height: 100px;
  }
  
  /* Main  */  
  .main-container {
    grid-area: main;
    overflow-y: auto;
    padding: 20px 20px;
    color: rgba(255, 255, 255, 0.95);
  }
  
  .main-title {
    display: flex;
    justify-content: space-between;
    color: #000;
  }
  
  .main-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin: 15px 0;
  }
  
  .card-dashboard {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 8px 15px;
    border-radius: 15px;
    gap: 25px;
    font-family: "Armstrong";
    color: #B1081A;
    background-color: #fff;
  }
  
  .cardf {
    border: 2.5px solid #2962ff;
  }
  
  .cardg {
    border:2.5px solid #ff6d00;
  }
  
  .cardh {
    border:2.5px solid #2e7d32;
  }
  
  .cardk {
    border:2.5px solid #d50000;
  }
  
  .card-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

  }
  .card-inner h3{
    color: #000 !important;
    font-size: 24px;
  }
  
  .card-inner > .card_icon {
    font-size: 25px;
  }
  
  .charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 60px;
    height: 300px;
  }
  
  /* End Main  */
  
  
  /* Medium <= 992px */
    
  @media screen and (max-width: 992px) {
    .grid-container {
      grid-template-columns: 1fr;
      grid-template-rows: 0.2fr 3fr;
      grid-template-areas:
        'header'
        'main';
    }
  
    #sidebar {
      display: none;
    }
  
    .menu-icon {
      display: inline;
    }
  
    .sidebar-title > span {
      display: inline;
    }
  }
  /* Small <= 768px */
    
  @media screen and (max-width: 768px) {
    .main-cards {
      grid-template-columns: 1fr;
      gap: 10px;
      margin-bottom: 0;
    }
  
    .charts {
      grid-template-columns: 1fr;
      margin-top: 30px;
    }
  }
  
  /* Extra Small <= 576px */
  
  @media screen and (max-width: 576px) {
    .hedaer-left {
      display: none;
    }
  }

  

  .editbtn{
    width: 35px;
    height: 35px;
    border-radius: 5px 15px 5px 15px;
    background-color: #76fd4085;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #000 !important;
    cursor: pointer;
  }
  .trashbtn{
    width: 35px;
    height: 35px;
    border-radius: 15px 5px 15px 5px;
    background-color: #fd404085;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
  }
  .morebtn{
    width: 36px;
    height: 35px;
    border-radius: 15px 15px 15px 15px;
    background-color: #c5c1c185;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
  }
  .pencil-outline{
    height: 15px !important;
  }
  .add-brand{
    color: #fff !important;
    font-weight: 800;
    font-family: Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    background-color: #B1081A !important;
    font-size: 0.975rem !important;
  }
  .fhfgfg{
    font-size: 35px;
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .add-circle-outline{
    font-size: 30px;
  }
  .brand-image{
    width: 50px;
    height: 50px;
    border-radius: 100px;
    background-color: transparent;
    object-fit: cover;
    border: 0.1px solid #000;
  }
.textgray{
  color: #808080 !important;
}

.icon-expo{
  font-size: 1.3rem;
  margin-right: 2px;
  margin-left: -3px;
}
  
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 500px;
  }
  .savesbtn button{
    border-radius: 15px;
  }
  /* thead th:first-child {
    background-color: #818181;
  }
  
  thead th:nth-child(2) {
    background-color: rgba(129, 129, 129, 0.90);
  }
  
  thead th:nth-child(3) {
    background-color: rgba(129, 129, 129, 0.80);
  }
  
  thead th:nth-child(4) {
    background-color: rgba(129, 129, 129, 0.70);
  }
  thead th:nth-child(5) {
    background-color: rgba(129, 129, 129, 0.60);
  }
  thead th:nth-child(6) {
    background-color: rgba(129, 129, 129, 0.50);
  }
  thead th:nth-child(7) {
    background-color: rgba(129, 129, 129, 0.40);
  }
  thead th:nth-child(8) {
    background-color: rgba(129, 129, 129, 0.20);
  }
  thead th:nth-child(9) {
    background-color: rgba(129, 129, 129, 0.10);
  }
  thead th:nth-child(10) {
    background-color: rgba(129, 129, 129, 0.0);
  }
  tbody th, td {
    background-color: rgba(129, 129, 129, 0.103) !important;
  }
  .form-control{background-color: rgba(129, 129, 129, 0.20);} */
  table{
    font-family: Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  }
  thead tr th{
    background-color: #f0f0f0 !important;
    border-color: #808080;
    font-size: 0.975rem;
    font-weight: 500;

  }
  tbody tr {
    height: 65px;
align-items: center;
font-size: 0.875rem !important;
  }
  thead, tbody, tfoot, tr, td, th {
    border-style: solid !important;
    border-color: #808080 !important;


}
.form-select{
  font-size: 20px !important;
  /* color: #000 !important; */
  font-weight: 600 !important;
}

  .container {max-width: 1100px; padding: 0 20px; margin:0 auto; display: flex; flex-direction: column-reverse;}
.panel { max-width: 500px; text-align: center;}
.button_outer {background: #B1081A; border-radius:5px; text-align: center; height: 50px; width: 200px; display: inline-block; transition: .2s; position: relative; overflow: hidden;}
.btn_upload {padding: 17px 30px 12px; color: #fff; text-align: center; position: relative; display: inline-block; overflow: hidden; z-index: 3; white-space: nowrap;}
.btn_upload input {position: absolute; width: 100%; left: 0; top: 0; width: 100%; height: 105%; cursor: pointer; opacity: 0;}
.file_uploading {width: 100%; height: 10px; margin-top: 20px; background: #ccc;}
.file_uploading .btn_upload {display: none;}
.processing_bar {position: absolute; left: 0; top: 0; width: 0; height: 100%; border-radius: 30px; background:#B1081A; transition: 3s;}
.file_uploading .processing_bar {width: 100%;}
.success_box {display: none; width: 50px; height: 50px; position: relative;}
.success_box:before {content: ''; display: block; width: 9px; height: 18px; border-bottom: 6px solid #fff; border-right: 6px solid #fff; -webkit-transform:rotate(45deg); -moz-transform:rotate(45deg); -ms-transform:rotate(45deg); transform:rotate(45deg); position: absolute; left: 17px; top: 10px;}
.file_uploaded .success_box {display: inline-block;}
.file_uploaded {margin-top: 0; width: 50px; background:#B1081A; height: 50px;}
.uploaded_file_view {max-width: 300px; margin: 40px auto; text-align: center; position: relative; transition: .2s; border: 2px solid #ddd; padding: 15px;}
.file_remove{width: 30px; height: 30px; border-radius: 50%; display: block; position: absolute; background: #aaa; line-height: 30px; color: #fff; font-size: 12px; cursor: pointer; right: -15px; top: -15px;}
.file_remove:hover {background: #222; transition: .2s;}
.uploaded_file_view img {max-width: 100%;}
.uploaded_file_view.show {opacity: 1;}
.error_msg {text-align: center; color: #B1081A}
.pagination-numbers{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
}
.pagination-numbers div{
  margin-left: 2px;
  margin-right: 2px;
  cursor: pointer;
  background-color: #B1081A;
width: 30px;  border-radius: 5px;
height: 30px;
  color: #fff;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-numbers div:hover{
  color: #fff !important;
  font-weight: 800;
  width: 30px; 
height: 35px !important;
transition: all 0.3s ease; 
}
.active-imp{
  color: #fff !important;
  font-weight: 800;
  width: 30px; 
height: 35px !important;
}
.pagination-numbers ion-icon{
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  font-size: 22px;
  color: #000;
}
.form-group div{
  width: 23%;
  height: 100%;
}
label{
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;

}
.carcardssection{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 20px;
  border-radius: 10px;
}
.uplord-card-section{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* gap: 20px; */
  row-gap: 0px !important;

  justify-content: space-between;
}
.uplord-card-section .card{
 width: 19.5%;
 margin-top: 20px;
 border-radius: 20px;
 border: none !important;
 box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 8px !important;
height: 350px;


}
.imagedivf{
  display: flex;
  align-items: center;
  justify-content: center;
}
.uplord-card-section .card img{
 box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 8px !important;
height: 150px;
margin: 0;
border-radius: 20px;

}
.pro-cons{
  gap: 7px;
  display: flex;
  flex-direction: column;
}
sup{
  color: #B1081A;
}
.inside-block{
  font-size: 18px !important;
}
.form-select-sm{
  border-color: #B1081A !important;
  outline:0px;

}
.information-form{
  width: 94% !important;
    display: flex !important;
    align-self: center !important;
    margin-bottom: 20px !important;
}
.active-pink-4 input[type=text]:focus:not([readonly]) {
  border: 1px solid #B1081A;
  box-shadow: 0 0 0 1px #B1081A;
}
select{
cursor: pointer;
background-color: #fff !important;
background: #fff !important;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
border: var(--bs-border-width) solid var(--bs-border-color) !important;
text-transform: uppercase !important;
}
option{
  text-transform: uppercase !important;

}
.cardata input{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 8px !important;
  background-color: #fff;
  transition: all 0.3s ease; 

}
.cardata input:focus {
  background-color: rgba(177, 8, 26, 0.1) !important;
  box-shadow: none !important;
  color: #000 !important;
  border: 1px solid #B1081A;
  transition: all 0.3s ease; 

}
select:focus {
  background-color: rgba(165, 165, 165, 0.1) !important;
  box-shadow: none !important;
  color: #000 !important;
  transition: all 0.3s ease; 
  border: 1px solid #B1081A;

}
.cardata textarea:focus {
  background-color: rgba(177, 8, 26, 0.1) !important;
  box-shadow: none !important;
  color: #000 !important;
  border: 1px solid #B1081A;
  transition: all 0.3s ease; 

}
.cardata textarea{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 8px !important;
  background-color: #fff;
  transition: all 0.3s ease; 
}
.bg-danger-new{
  background-color:#B1081A !important ;
}
/* .colore-card > .card:first-child {
  color: red;
} */
/* .colore-card > .card:nth-child(2) {
  background-color: rgba(117, 113, 112, 0.5);
} */

.card-body h5,input{
  z-index: 5;
}
.card input{
  z-index: 5;
  position: relative;
}
.colore-card > .card:nth-child(2) {
  background-color: rgba(117, 113, 112, 0.5);
 
}
.colore-card > .card:nth-child(3) {
  background-color: rgba(254, 0, 0, 0.5);
}
.colore-card > .card:nth-child(4) {
  background-color: rgba(51, 83, 152, 0.5);
}
.colore-card > .card:nth-child(5) {
  background-color: rgba(0, 0, 0, 0.7);
  /* color: #fff; */
}
.colore-card > .card:nth-child(6) {
  background-color: rgba(0, 175, 80, 0.5);
}
.colore-card > .card:nth-child(7) {
  background-color: rgba(244, 177, 132, 0.5);
}
.colore-card > .card:nth-child(8) {
  background-color: rgba(132, 60, 12, 0.5);
}
.colore-card > .card:nth-child(9) {
  background-color: rgba(255, 192, 1, 0.5);
}
.colore-card > .card:nth-child(10) {
  background-color: rgba(112, 48, 159, 0.5);
}
.colore-card > .card:nth-child(11) {
  background-color: rgb(255, 255, 255);
}
TH{
  color: #000 !important;
}
.dropdown-content{
  position: absolute;
  right: 20px;
  /* left: 80%; */
  z-index: 5;
  top: 45px;
  background-color: #fff;
  padding: 20px 20px 20px 20px;
  border-radius: 10px;
  border: 1px solid #B1081A;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.header-right .icon{
  font-size: 25px; 
  cursor: pointer;
}
.header-right{
  display: flex;
  align-items: center;
  gap: 5px;
}
.profilesection{
  text-transform: capitalize;
  display: flex;
  gap: 5px;
  /* padding: 5px; */
  border-radius: 10px;
  /* border: 1px solid #B1081A; */
}
.dropdown-content div{
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.dropdown-content div:hover{
 color: #B1081A;
 text-decoration: underline;
}
.drop-icon{
  margin-left: -20px;
  /* margin-top: 20px; */
}
.drop-icon1{
  margin-left: -40px;
  margin-top: 20px;
}
.drop-icon2{
  margin-left: -40px;
  margin-top: 30px;
}
.drop-icon3{
  margin-left: -40px;
  margin-top: 38px;
}
.bg-danger-new{
  background-color: #B1081A !important;
}
img{
  background-color: transparent !important;
}
.form-check-input{
  width: 60px !important;
  height: 30px !important;
  border: transparent !important;
  background-color: #fff ;
}
.form-check-input:checked {
  background-color: #B1081A !important;
}

.form-check-input::-webkit-slider-thumb{
  background-color: #B1081A !important;

}
.ml-1 {
  margin-left: 30px;
}
.form-check-input:focus {
  background-color: #B1081A !important;
}
.form-check-input.clicked {
  background-color: #B1081A !important;
  background: none;
}



.main-container-fullm {
  /* display: flex; */
  width: 100%; /* Double the width to accommodate both steps */
  transition: margin-left 0.4s ease-in-out; 
  overflow-x: hidden !important;
}
.main-container-full {
  overflow-x: hidden !important;
}

.main-container-fullm .step {
  flex: auto;
  overflow-x: hidden !important;

}

.main-container-fullm.slide-left {
  /* margin-left: -100%; */
  overflow-x: hidden !important;

}

.addproductdata, .progress-stacked {
  --bs-progress-bg: #000 !important;
  --bs-progress-border-radius: #000 !important;
}
.next-btnadd{
  background-color: #B1081A;
  padding: 10px;
  border-radius: 10px 0 0 0 ;
  color: #fff;
  cursor: pointer;
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 22px;
  z-index: 9;
}
.save-btnadd{
  background-color: #B1081A;
  padding: 7px;
  border-radius: 18px 18px 0 0 ;
  color: #fff;
  cursor: pointer;
  width: 6%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 40%;
  z-index: 9;
}
.previous-btnadd{
  background-color: #D83F31;
  padding: 10px;
  border-radius: 0px 10px 0 0 ;
  color: #fff;
  cursor: pointer;
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 260px;
  z-index: 9;
}
#text-areas{
  width: 400px;
}
.reactquiltext div{
  width: 100% !important;
}
.image-left{
  margin-left: 1rem;
  text-transform: capitalize;
  line-height: 1.6rem;
}
.image-left span{
  text-transform:lowercase !important;
}
.mt-1rem{
  margin-top: 0.85rem;
}
.rdrInputRanges{
  display: none;
}
.rdrDateRangePickerWrapper button .rdrStartEdge ,.rdrDateRangePickerWrapper button .rdrDayWeekend ,.rdrDateRangePickerWrapper button .rdrDayEndOfWeek{
  background-color: #B1081A !important;
  border-color: #B1081A !important;

}
/* .rdrDayWeekend {
  background-color: #B1081A !important;
} */
/* .rdrDay{
  background-color: #B1081A  !important;
} */
.rdrEndEdge, .rdrInRange{
  background-color: #B1081A !important;
  border-color: #B1081A !important;

}
.rdrDayHovered{
  border-color:  #B1081A !important;

}
.rdrDayToday .rdrDayNumber span:after{
  background: #B1081A !important;
  border-color: #B1081A !important;
}
.rdrNextPrevButton:hover{
  border-color: #B1081A !important;
}
.rdrDayToday .rdrDayNumber span:after{
  background: #B1081A !important;
  border-color: #B1081A !important;
}
.rdrDateDisplayItemActive {
  border-color: #B1081A !important;
}
.rdrCalendarWrapper {
  width: fit-content;
}
.rdrDefinedRangesWrapper{
  width: 100% !important;
}
.rdrDateRangePickerWrapper{
  align-items: center !important;
}
.rdrStaticRangeSelected{
  color: #B1081A !important;
  border:1px solid #B1081A !important;
}
.rdrStaticRange{
  color: #000 !important;
  width: 160px !important;
}
.icon-expo-new{
  font-size: 1rem;
  margin-right: 3px;
  margin-top: -2px;
}
.-mfjgk ,.dghdghg{
  font-size: 1rem;
  margin-left: 5px !important;
  margin-top: -4px !important;
  margin-right: 3px !important;
}
button ,.btn , a , div{
  outline: none !important;
}
.icon-expo-new.rotated {
  transition: transform 0.3s ease-in-out;
  transform: rotate(180deg);
}






/* BigImage.css */

.modal {
  background: #fff;
  padding: 20px;
  max-width: 80%;
  max-height: 80%;
  position: relative;
}

.displayimage img {
  width: 70% !important;
  border-radius: 20px;
  margin: 20px;
}
.displayimage{
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #000;
  cursor: pointer;
  font-size: 20px;
}



body.dark-mode {
  background-color: #000 !important;
  color: #fff !important;
}





.step1-active .step {
  display: none;
}
.step1-active .step:nth-child(1) {
  display: block; /* Display only the first step when on Step 1 */
}




.loader {
  width: 64px;
  height: 64px;
  position: relative;
  animation: rotate 1.5s ease-in infinite alternate;
  
}
.loader::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  color: #FF3D00;
  background: currentColor;
  width: 64px;
  height: 32px;
  border-radius: 0 0 50px 50px;
}
.loader::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 10%;
  background: #000;
  width: 8px;
  height: 64px;
  animation: rotate 1.2s linear infinite alternate-reverse;
}

@keyframes rotate {
  100% { transform: rotate(360deg)}
}
      

.copy-write{
  color: #fff;
  position: absolute;
  bottom: 10px;
  margin-left: 20px;
}
.form-switch .form-check-input {
  background-color: #dbd6d6;
}
.brand-image-under{
  /* width: 20px; */
  height:20px;
  position: relative;
  margin-top: -30px;
  margin-right: -20px;
  /* background-color: #B1081A;
  border: 1px dotted #B1081A;
  border-radius: 2px; */
}
.active-btn{
  color: #fff;
  font-weight: 600;
  padding: 2px 7px ;
  text-align: center;
  /* font-size: 10px; */
  border-radius: 40px;
  background-color: #42c049;
}
.disable-btn{
  color: #fff;
  font-weight: 800;
  padding: 7.5px 7.5px;
  border-radius: 40px;
  width: fit-content;
  background-color: #B1081A;
  display: flex;
  align-items: center;
  /* margin-left: 17px; */
  font-size: 15px;
  
}
.inwart{
  padding: 0px 15px !important;
    height: 38px;
}
.edit-text{
  font-size: 30px !important;
}








.App {
  position: absolute;
  bottom: 20px;
  right: 30px;
}

.input-hidden {
  color: #B1081A;
  font-size: 0;
  width: 70px;
  height: 70px;
  border-radius: 50px;
  background: transparent;
  border: none !important;
  position: absolute;
  top: -0px;
}

.input-hidden::-webkit-file-upload-button {
  display: none;
}

.upload-container {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  background-color: #B1081A;
  overflow: hidden;
}

.MdCloudUpload {
  width: 70px;
  height: 40px;
  display: flex;
  margin-top: 12px;
  color: #fff;
}

.upload-container.upload-animation {
  animation: border-pulse 1s infinite;
}
.savebuton{
  position: absolute;
  bottom: 20px;
  right: 50px;
  color: #fff;
  /* z-index: -1; */
  background-color: #B1081A;
  padding: 5px 15px;
  border-radius: 9px;
  transition: right 0.3s ease;
  cursor: pointer;
}



.Toastify__toast-body {
  
  width: 1680px !important;
}
.ant-pagination-item a{
  color: #000 !important;
}
.maingiufull{
  padding: 10px 50px 10px 50px;
}
.popup {
  overflow: overlay;
}




/* :where(.css-dev-only-do-not-override-djtmh8).ant-table-wrapper .ant-table-cell, :where(.css-dev-only-do-not-override-djtmh8).ant-table-wrapper .ant-table-thead>tr>th, :where(.css-dev-only-do-not-override-djtmh8).ant-table-wrapper .ant-table-tbody>tr>th, :where(.css-dev-only-do-not-override-djtmh8).ant-table-wrapper .ant-table-tbody>tr>td, :where(.css-dev-only-do-not-override-djtmh8).ant-table-wrapper tfoot>tr>th, :where(.css-dev-only-do-not-override-djtmh8).ant-table-wrapper tfoot>tr>td {
  position: relative;
  padding: 0 !important;
  overflow-wrap: break-word;
} */
.ant-table-thead > tr > th:last-child {
  width: 150px;
  /* display: flex; */

}
    tbody > tr > td:last-child {

/* display: flex; */
  width: 150px;
}


.text-custom-green {
  color: #28a745 !important;  
}

.text-success {
  color: #28a745 !important; 
}
.ant-select-selection-placeholder{
  color: #000 !important;
    font-weight: 600;

}
.ant-table-cell > tr > td:nth-last-child(2) {
  width: 110px !important;
    /* overflow: hidden; */
    display: flex;
    height: inherit;
}
.ant-table-thead > tr > th:nth-last-child(2) {
  width: 110px;
}
.review-car{
  font-size: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}
.dfgbdmnfgdfmg{
  width: auto;
    margin-bottom: 15px;
    height: 350px;
    display: flex;
    margin: auto;
}
/* .review-car span {
  width: 50%;
} */
 .fksdksdksdgfhdghh{
  width: 60% !important;
}
.table_homepage tr{
  height: 25px;

}